import { Amplify } from "aws-amplify";
import awsExportsTest from "./aws-exports-test";
import awsExportsProd from "./aws-exports-prod2";
import axios from "axios";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import S3 from "aws-sdk/clients/s3";

import log from "./logger";

const PROD_API = "https://api.topstorie.com/api2/";
const TEST_API = "https://api.topstorie.com/api-test/";
const OFFLINE_API = "http://localhost:3000/test/";

export const postHttpApi = async (api: string, path: string, data: any) => {
  // log.debug(await Auth.currentUserInfo());

  if (!data.project && path !== "admin" && data.action !== "projects") {
    throw new Error(`Project is empty when requesting ${path}`);
  }

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  return axios.post(api + path, data, {
    headers: { Authorization: token },
  });
};

export const refreshToken = async (onSuccess: () => void) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken();
    user.refreshSession(refreshToken, () => {
      onSuccess();
    });
  } catch (e) {
    log.warn("Unable to refresh Token", e);
  }
};

export function getApiUrl(stage: string): string {
  if (stage === "test") {
    return TEST_API;
  } else if (stage === "offline") {
    return OFFLINE_API;
  }
  return PROD_API;
}

export function getHookUrl(stage: string): string {
  if (stage === "test") {
    return "https://hooktest.topstorie.com/";
  } else if (stage === "offline") {
    return OFFLINE_API;
  } else {
    return "https://hook.topstorie.com/";
  }
}

export function switchStage(stage: string) {
  configureLogin(stage);
}

function configureLogin(stage: string) {
  const awsExports = stage === "test" ? awsExportsTest : awsExportsProd;

  // Configure Amplify in index file or root file
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      identityPoolId: awsExports.IDENTITY_POOL_ID,
    },
  });
}

const connectS3 = async () => {
  const creds = await Auth.currentCredentials();
  const s3 = new S3({
    region: "eu-west-1",
    credentials: Auth.essentialCredentials(creds),
  });

  return s3;
};

export const downloadFileFromS3Bucket = async (key: string, bucket: string) => {
  const s3 = await connectS3();

  var params = {
    Bucket: bucket,
    Key: key,
  };

  const obj = await s3.getObject(params).promise();
  return obj.Body;
};

const createFileKey = async (file: File, projectName: string) => {
  const user = await Auth.currentUserInfo();

  const sub = user.username;
  const filename = `${projectName}-${uuidv4()}-${file.name}`;
  const key = `cognito/topstorie/${sub}/${filename}`;

  return key;
};

const uploadToS3OnePiece = async (
  file: File,
  projectName: string,
  userUploadBucket: string
) => {
  try {
    const s3 = await connectS3();
    const key = await createFileKey(file, projectName);

    var params = {
      Bucket: userUploadBucket,
      Key: key,
      Body: file,
    };

    await s3.putObject(params).promise();
    return `s3://${userUploadBucket}/${key}`;
  } catch (e) {
    log.debug(e);
  }
};

const uploadToS3MultiPart = async (
  file: File,
  projectName: string,
  userUploadBucket: string
): Promise<string | undefined> => {
  const s3 = await connectS3();
  const key = await createFileKey(file, projectName);

  let uploadId: string | undefined = undefined;

  try {
    const params = {
      Bucket: userUploadBucket,
      Key: key,
    };

    const multipartUpload = await s3.createMultipartUpload(params).promise();
    uploadId = multipartUpload.UploadId;

    if (!uploadId) {
      return undefined;
    }

    const uploadPromises = [];

    let parts = [];
    let sizeLeft = file.size;
    const partSize = 5 * 1024 * 1024;
    while (sizeLeft >= 2 * partSize) {
      parts.push(partSize);
      sizeLeft -= partSize;
    }
    parts.push(sizeLeft);

    let pos = 0;
    for (let i = 0; i < parts.length; i++) {
      const start = pos;
      const end = start + parts[i];
      pos += parts[i];

      uploadPromises.push(
        s3
          .uploadPart({
            Bucket: userUploadBucket,
            Key: key,
            UploadId: uploadId,
            Body: file.slice(start, end),
            PartNumber: i + 1,
          })
          .promise()
      );
    }

    const uploadResults = await Promise.all(uploadPromises);

    await s3
      .completeMultipartUpload({
        Bucket: userUploadBucket,
        Key: key,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: uploadResults.map(({ ETag }, i) => ({
            ETag,
            PartNumber: i + 1,
          })),
        },
      })
      .promise();

    return `s3://${userUploadBucket}/${key}`;
  } catch (err) {
    log.error("multipart upload crashed");
    log.error(err);

    if (uploadId) {
      await s3
        .abortMultipartUpload({
          Bucket: userUploadBucket,
          Key: key,
          UploadId: uploadId,
        })
        .promise();
    }
  }
  return undefined;
};

let testUploadFailure = 0;

export const uploadToS3 = async (
  file: File,
  projectName: string,
  userUploadBucket: string
) => {
  if (!projectName) {
    throw new Error("Project is empty when uploading a file to S3.");
  }

  if (!userUploadBucket) {
    throw new Error("User upload bucket is not defined.");
  }

  testUploadFailure -= 1;
  if (testUploadFailure >= 0) {
    return null;
  }
  if (file.size < 10 * 1024 * 1024) {
    return await uploadToS3OnePiece(file, projectName, userUploadBucket);
  } else {
    return await uploadToS3MultiPart(file, projectName, userUploadBucket);
  }
};
