import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Icon } from "@iconify/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TurnedInIcon from "@mui/icons-material/TurnedIn";

import { useState, useEffect } from "react";

import { activeLabelsState } from "./JotaiAtoms";

import { useHttpPost } from "./hooks";
import * as u from "./utility";
import * as lodash from "lodash";

import { AutocompleteButton } from "./AutocompleteButton";
import { useAtomValue } from "jotai";

export function StoryLabels({
  item,
  onLabelCreated,
  onUpdateLabels,
  minimized,
  labels,
  setLabels,
  diff,
  setDiff,
  disabled,
}) {
  const activeLabels = useAtomValue(activeLabelsState);
  const immutableLabels = new Set(["github", "slack", "gitlab"]);
  const [input, setInput] = useState(false);
  const [ownLabels, setOwnLabels] = useState([]);
  const httpPost = useHttpPost();

  const reloadLabels = (item, diff) => {
    let set = setLabels || setOwnLabels;

    if (!item) {
      return;
    }

    let newLabels = [];
    if (diff) {
      for (let [k, v] of Object.entries(diff)) {
        if (v) {
          newLabels.push(k);
        }
      }
    }
    if (item.st_labels) {
      for (let l of item.st_labels) {
        if (!newLabels.includes(l) && (!diff || !(l in diff) || diff[l])) {
          newLabels.push(l);
        }
      }
    }

    if (activeLabels) {
      newLabels.sort((a, b) => {
        if (a in activeLabels && b in activeLabels) {
          return activeLabels[a].index - activeLabels[b].index;
        } else if (a in activeLabels) {
          return -1;
        } else if (b in activeLabels) {
          return 1;
        }
        return 0;
      });
    }
    set((prev) => (u.equalArrays(prev, newLabels) ? prev : newLabels));
  };

  useEffect(() => {
    reloadLabels(item, null);
    if (setDiff) {
      setDiff((prev) => {
        if (!prev || lodash.isEmpty(prev)) {
          return prev;
        } else {
          return {};
        }
      });
    }
  }, [item, setLabels, setOwnLabels, setDiff]);

  useEffect(() => {
    reloadLabels(item, diff);
  }, [activeLabels, setLabels, setOwnLabels]);

  const resetLabels = (labels) => {
    let set = setLabels || setOwnLabels;
    set(labels);
    if (onUpdateLabels) {
      onUpdateLabels(item.SK, labels);
    }
    if (diff) {
      let newDiff = { ...diff };
      for (let [k] of Object.entries(newDiff)) {
        newDiff[k] = labels.includes(k);
      }
      for (let k of labels) {
        newDiff[k] = true;
      }
      setDiff(newDiff);
    }
  };

  const handleCreate = (newValue) => {
    let ll = labels || ownLabels;
    let set = setLabels || setOwnLabels;

    const request = async (labels) => {
      try {
        const response = await httpPost("label", {
          set: {
            story: item.SK,
            labels: labels,
          },
        });
        if (response.data.labels) {
          resetLabels(response.data.labels);
        }
      } catch (err) {
      } finally {
      }
    };

    if (!newValue) {
      return;
    }

    const splitLabels = newValue.trim().split(" ");
    let validatedLabels = [];
    for (let label of splitLabels) {
      if (label && !ll.includes(label)) {
        for (let [k] of Object.entries(activeLabels)) {
          if (activeLabels[k].display === label) {
            label = k;
            if (ll.includes(k)) {
              return;
            }
          }
        }
        if (label.match(/^[0-9a-zA-Z:/_]+$/)) {
          validatedLabels.push(label);
        }
      }
    }

    if (validatedLabels.length) {
      if (item) {
        request(validatedLabels);
      }

      if (onLabelCreated) {
        let totallyNew = [];
        for (let l of validatedLabels) {
          if (!(l in activeLabels)) {
            totallyNew.push(l);
          }
        }

        if (totallyNew.length) {
          onLabelCreated(totallyNew);
        }
      }

      let newLabels = [...ll];
      let newDiff = diff ? { ...diff } : null;
      for (let label of validatedLabels) {
        if (!newLabels.includes(label)) {
          newLabels.push(label);
          if (newDiff) {
            newDiff[label] = true;
          }
        }
      }
      if (onUpdateLabels) {
        onUpdateLabels(item.SK, newLabels);
      }
      set(newLabels);
      if (newDiff) {
        setDiff(newDiff);
      }
    }
  };

  const handleDelete = (label, index) => {
    const request = async () => {
      try {
        httpPost("label", { unset: { story: item.SK, labels: [label] } });
      } catch (err) {
      } finally {
      }
    };

    let newLabels = labels || ownLabels;
    let set = setLabels || setOwnLabels;
    newLabels.splice(index, 1);
    set([...newLabels]);
    if (onUpdateLabels) {
      onUpdateLabels(item.SK, newLabels);
    }
    if (diff) {
      let newDiff = { ...diff };
      newDiff[label] = false;
      setDiff(newDiff);
    }
    if (item) {
      request();
    }
  };

  const getLabelDisplayName = (label) => {
    if (activeLabels && label in activeLabels) {
      return activeLabels[label].display;
    }

    return label;
  };

  const isIconLabel = (label) => {
    if (activeLabels && label in activeLabels) {
      return typeof activeLabels[label].display === "object";
    }

    return false;
  };

  const getAutocompleteOptions = () => {
    if (!activeLabels) {
      return [];
    }

    let result = Object.entries(activeLabels).map(([k, v]) => k);

    result.sort((a, b) => {
      if (a in activeLabels && b in activeLabels) {
        return activeLabels[a].index - activeLabels[b].index;
      } else if (a in activeLabels) {
        return -1;
      } else if (b in activeLabels) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < result.length; ++i) {
      const v = activeLabels[result[i]];
      if (typeof v.display === "string" && !result.includes(v.display)) {
        result[i] = v.display;
      }
    }

    return result;
  };

  return (
    <>
      {(!minimized || input) && (
        <Grid
          container
          spacing={0.1}
          direction="row-reverse"
          justifyContent="flex-end"
          width="auto"
        >
          {(labels || ownLabels).map((label, i) => (
            <Grid item key={label}>
              {!isIconLabel(label) && (
                <Chip
                  sx={{
                    "& .MuiChip-label": {
                      fontSize: 9,
                    },
                  }}
                  size="small"
                  label={getLabelDisplayName(label)}
                  disabled={disabled || immutableLabels.has(label)}
                  onClick={() => {
                    handleDelete(label, i);
                  }}
                />
              )}

              {isIconLabel(label) && (
                <IconButton
                  size="small"
                  disabled={disabled || immutableLabels.has(label)}
                  onClick={() => {
                    handleDelete(label, i);
                  }}
                >
                  <Icon icon={activeLabels[label].display.icon} />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      )}
      {true && (
        <AutocompleteButton
          options={getAutocompleteOptions()}
          onSelected={handleCreate}
          variant="standard"
          placeholderText="Enter a new label"
          onInput={(v) => minimized && setInput(v)}
          disabled={disabled}
        >
          {!minimized && (
            <AddCircleIcon color={"disabled"} fontSize="inherit" />
          )}
          {minimized && <TurnedInIcon color={"disabled"} fontSize="inherit" />}
        </AutocompleteButton>
      )}
    </>
  );
}
