import { useState, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Autocomplete from "@mui/material/Autocomplete";

import { captionsState } from "./JotaiAtoms";

import { useAtom } from "jotai";
import { useHttpPostAndProject } from "./hooks";

function CaptionButtonInput({ story, initialValue, onSelected }) {
  const [value, setValue] = useState(initialValue);
  const ref = useRef();
  const [httpPost, project] = useHttpPostAndProject();

  const [captions, setCaptions] = useAtom(captionsState);

  const handleCaption = (value) => {
    const request = async () => {
      try {
        httpPost("label", {
          setstoryprops: {
            story: story,
            st_cap: value,
          },
        });
        if (value !== "") {
          const proj = project;
          setCaptions((prev) => {
            if (proj in prev) {
              let newCaps = { ...captions };
              newCaps[proj] = { ...prev[proj], [value]: story };
              return newCaps;
            }
            return prev;
          });
        }
      } catch (err) {
      } finally {
      }
    };

    if (value || value === "") {
      request();
    }
    if (onSelected) {
      onSelected(value);
    }
  };

  const handleKey = (e) => {
    ref.current = e.key;
    if (e.key === "Enter") {
      e.preventDefault();
      handleCaption(value || "");
    } else if (e.key === "Escape") {
      handleCaption(null);
    }
  };

  const saveText = (v) => {
    if (v) {
      if (v.trimStart().length <= 18) {
        setValue(v.trimStart());
      }
    } else {
      setValue(null);
    }
  };

  const handleChange = (e, v) => {
    saveText(v);
  };

  const handleTextChange = (e) => {
    saveText(e.target.value);
  };

  return (
    <Autocomplete
      value={value}
      freeSolo
      autoHighlight
      autoSelect
      clearOnEscape
      disableClearable
      onChange={handleChange}
      options={captions[project] ? Object.keys(captions[project]) : []}
      size="small"
      sx={{ pl: 1, width: 155 }}
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 18 }}
          value={value}
          sx={{ fontSize: 9, width: "100%" }}
          size="small"
          variant={"standard"}
          autoFocus
          onBlur={() => handleCaption(null)}
          onKeyDown={handleKey}
          onChange={handleTextChange}
        />
      )}
    />
  );
}

function CaptionButton({ story, initialValue, onSelected, color, icon }) {
  const [input, setInput] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSelected = (value) => {
    setInput(false);
    if (value != null) {
      setValue(value);
    }
  };

  return (
    <Box>
      {!input && (
        <>
          {!value && (
            <IconButton
              size="small"
              onClick={() => {
                setInput(true);
              }}
            >
              {icon ? (
                icon
              ) : (
                <MoreHorizIcon color={color ? color : "disabled"} />
              )}
            </IconButton>
          )}

          <Box
            onClick={() => {
              setInput(true);
            }}
          >
            {value && (
              <Chip
                size="small"
                disabled={false}
                sx={{
                  background: "#c0d1dc",
                  width: "150px",
                  alignItems: "center",
                }}
                label={value}
              />
            )}
          </Box>
        </>
      )}
      {input && (
        <CaptionButtonInput
          story={story}
          initialValue={value}
          onSelected={handleSelected}
        />
      )}
    </Box>
  );
}

export function StoryCaption({ item, initialValue }) {
  return <CaptionButton initialValue={initialValue} story={item.SK} />;
}
