import { useState, useRef } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export function AutocompleteButton({
  onSelected,
  options,
  variant,
  children,
  placeholderText,
  onInput,
  disabled,
  onTextInput,
  sx,
  renderOption,
}) {
  const [input, setInput] = useState(false);
  const ref = useRef();

  const changeInput = (state) => {
    setInput(state);
    if (onInput) {
      onInput(state);
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSelected(e.target.value, e);
      changeInput(false);
    } else if (e.key === "Escape") {
      changeInput(false);
    } else if (e.key === "Tab") {
      ref.current = e;
    }
  };

  const handleChange = (e, val) => {
    if (e.type === "click") {
      onSelected(val, e);
    } else if (e.type === "blur" && ref.current && ref.current.key === "Tab") {
      onSelected(val, ref.current);
    }
    changeInput(false);
  };

  const handleTextInput = (e) => {
    if (onTextInput) {
      onTextInput(e.target.value);
    }
  };

  return (
    <>
      {!input && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              changeInput(true);
            }}
            disabled={disabled}
          >
            {children}
          </IconButton>
        </Box>
      )}
      {input && (
        <Box sx={{ maxWidth: "150px", width: "100%" }}>
          <Autocomplete
            freeSolo
            autoHighlight
            autoSelect
            clearOnEscape
            onChange={handleChange}
            options={options}
            size="small"
            sx={{ pl: 1 }}
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ fontSize: 9, width: "100%", ...(sx ? sx : {}) }}
                size="small"
                variant={variant}
                autoFocus
                placeholder={placeholderText}
                onChange={handleTextInput}
                onBlur={(e) => {
                  // give a chance to downstream event to handle
                  // like allow the label to be removed from StoryLabels when working in
                  // minimized form
                  setTimeout(() => changeInput(false), 120);
                }}
                onKeyDown={handleKey}
              />
            )}
          />
        </Box>
      )}
    </>
  );
}
