import { atom } from "jotai";

import * as u from "./utility";

import store from "store2";

export const imageToggleAtom = atom<boolean>(
  u.readLocalStorage("ImageToggle") !== "false"
);

export const projectPickerState = atom<string>(
  u.projectFromLocation() ||
    (window.location.pathname === "/"
      ? store.get("ProjectPicker:" + window.location.search, "")
      : "")
);

export const timeFilterAtom = atom<string>("");

export const timeFilterState = atom(
  (get): string => {
    const prev: string = get(timeFilterAtom);
    const proj: string = get(projectPickerState);
    const key = u.generateCacheKey("TimeFilter", proj);
    const cached = store.get(key, "all time");

    return prev !== cached ? cached : prev;
  },
  (get, set, newValue: string) => {
    const proj = get(projectPickerState);
    const key = u.generateCacheKey("TimeFilter", proj);
    store(key, newValue);
    set(timeFilterAtom, newValue);
  }
);

export const debugToggleState = atom<boolean>(
  u.readLocalStorage("DebugToggle") !== "false"
);

export const captionsState = atom<any>(JSON.parse(store.get("Captions", "{}")));

export const peopleDataState = atom(null);

export const negateLabelsState = atom<boolean>(
  u.readLocalStorage("NegateLabels", "") === "true"
);

export const labelToggleState = atom<boolean>(
  u.readLocalStorage("LabelToggle") !== "false"
);

export const searchState = atom<string>("");

export const newStoriesState = atom<any[]>([]);

export const mergedStoriesState = atom([]);

export const joinStoriesState = atom([]);

export const reloadState = atom<number>(0);

export const showSelectedStoriesState = atom<string>("false");

export const selectedCategory = atom<string>("");

export const storyFilterBoxState = atom<any>();

export const activeLabelsState = atom<any>();
