import { useState, useEffect, useRef, memo, useCallback } from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";

import { joinStoriesState } from "./JotaiAtoms";
import { useAtom } from "jotai";

import { StoryRow } from "./StoryRow";

const MemoStoryRow = memo(StoryRow);

export function ShowStories({
  data,
  onPin,
  onDelete,
  onRestore,
  onUpdateLabels,
  collapseAll,
  onLabelCreated,
  mode,
  dataSource,
}) {
  console.log(`show stories ${data ? data.length : null}`);

  const [selectedRange, setSelectedRange] = useState();
  let ref = useRef(null); // store last selected index
  const [selected, setSelected] = useAtom(joinStoriesState);

  useEffect(() => {
    ref.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedRange && data) {
      let first = data.findIndex((e) => e.SK === selectedRange[0]);
      let last = data.findIndex((e) => e.SK === selectedRange[1]);
      if (first !== -1 && last !== -1) {
        setSelected((prev) => {
          let newSelected = { ...prev };
          for (
            let i = Math.min(first, last) + 1;
            i < Math.max(first, last);
            ++i
          ) {
            if (!(data[i].SK in prev) || !prev[data[i].SK]) {
              newSelected[data[i].SK] = true;
            }
          }
          return newSelected;
        });
      }
      setSelectedRange(null);
    }
  }, [data, selectedRange, setSelected]);

  const handleSelected = useCallback(
    (e, story_id, storySelected) => {
      setSelected((prev) => {
        let newSelected = { ...prev, [story_id]: storySelected };
        if (storySelected && e && e.shiftKey && ref.current) {
          setSelectedRange([story_id, ref.current]);
          ref.current = story_id;
        } else if (storySelected) {
          ref.current = story_id;
        } else {
          ref.current = null;
        }
        return newSelected;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected, setSelectedRange]
  );

  const handleDelete = useCallback(
    (item) => {
      if (onDelete) {
        onDelete(item);
      }
    },
    [onDelete]
  );

  return (
    <Stack direction="column">
      {data && data.length > 1 && (
        <Typography
          fontSize={8}
          fontFamily={"monospace"}
          sx={{ color: "grey", m: 0.3, p: 0.5, mb: 1, pl: 1, pr: 1 }}
          style={{ width: "fit-content" }}
          backgroundColor="#e6f2ff"
          borderRadius={3}
        >
          {`${data.length} stories displayed`}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table sx={{}} size="small" aria-label="simple table">
          <TableBody>
            {data &&
              data.map((item, i) => (
                <MemoStoryRow
                  key={
                    item.old_dummy_message ? item.old_dummy_message.SK : item.SK
                  }
                  item={item}
                  onSelected={handleSelected}
                  onPin={onPin}
                  onDelete={handleDelete}
                  onRestore={onRestore}
                  onUpdateLabels={onUpdateLabels}
                  collapseAll={collapseAll}
                  deleted={mode === "deleted"}
                  onLabelCreated={onLabelCreated}
                  mode={mode}
                  selectedRow={item.SK in selected && selected[item.SK]}
                  smoothCollapseAnimation={i < 30}
                  expand={dataSource == "search" && data.length == 1}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
