import { useRef, useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { Icon } from "@iconify/react";
import { red, green } from "@mui/material/colors";
import * as lodash from "lodash";
import Tooltip from "@mui/material/Tooltip";
import store from "store2";
import * as riki from "jsriki";
import { ColoredSwitch } from "./ColoredSwitch";

import {
  negateLabelsState,
  reloadState,
  projectPickerState,
} from "./JotaiAtoms";

import { storyFilterBoxState, activeLabelsState } from "./JotaiAtoms";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useHttpPostAndProject } from "./hooks";

import * as u from "./utility";

import { AutocompleteButton } from "./AutocompleteButton";
import log from "./logger";

function isValidExpression(text: string, labelLookup: Set<string>): boolean {
  const trimmed = text.trim();
  if (trimmed) {
    let valid = riki.validate_expression(trimmed);
    if (valid) {
      const ops = riki.extract_expression_operands(trimmed);
      for (let op of ops) {
        if (!labelLookup.has(op)) {
          const norm = normalizeCompositeLabel(op);
          if (norm === op || !labelLookup.has(norm)) {
            valid = false;
            break;
          }
        }
      }
    }
    return valid;
  } else {
    return true;
  }
}

type LabelIcon = {
  icon: string;
};

type Label = {
  index: number;
  display: string | LabelIcon;
  value: string;
  name: string;
  expression?: string;
  user_created?: boolean;
};

type ApiQuery = {
  updated: string;
  query: string;
  created: string;
  name: string;
  author: string;
  calls: number;
};

type ApiObject = {
  app: string;
  name: string;
  full_name: string;
  id: string;
  type: string;
};

type ApiLabel = {
  name: string;
  calls: number;
};

type SlackChannel = {
  name_normalized: string;
  name: string;
};

function substituteExpression(expression: string, labels: Label[]): string {
  let origExpr: string = normalizeExpression(expression.trim());
  let normExpr: string = origExpr;

  // replace display names with actual names
  const plainLabels = labels.filter((e) => !e.expression);
  for (const label of plainLabels) {
    if (typeof label.display === "string" && label.display !== label.name) {
      normExpr = normExpr.replace(
        " " + label.display + " ",
        " " + label.name + " "
      );
    }
  }

  // reqursively expand queries, with max depth
  let queries = labels.filter((e) => e.expression);
  let placeholders = [];
  for (let i = 0; i < 3 && queries.length > 0; ++i) {
    const numPlaceholders = placeholders.length;
    for (const label of queries) {
      const placeholder = `$${placeholders.length}`;
      const subst = normalizeExpression(
        `(${placeholder} or (${label.expression})) `
      );
      const replaced = normExpr.replace(` ${label.name} `, subst);
      if (replaced !== normExpr) {
        normExpr = replaced;
        placeholders.push(label.name);
      }
    }

    if (placeholders.length === numPlaceholders) {
      break;
    }
  }

  for (let i = 0; i < placeholders.length; ++i) {
    normExpr = normExpr.replace(` $${i} `, ` ${placeholders[i]} `);
  }

  return normExpr === origExpr ? expression : normExpr;
}

export function LabelsData() {
  const reloadData = useAtomValue(reloadState);
  const setActiveLabels = useSetAtom(activeLabelsState);
  const [httpPost, project] = useHttpPostAndProject();

  const getData = async (project: string) => {
    try {
      const response = await httpPost("stories", {
        activelabels: true,
        project: project,
      });

      const newLabels = response.data.activelabels;
      const dummyIndex = 9999;

      let mapping: Map<string, Label> = new Map();
      mapping.set(":white_check_mark:", {
        index: dummyIndex,
        name: ":white_check_mark:",
        value: ":white_check_mark:",
        display: { icon: "material-symbols:check-box-rounded" },
      });
      mapping.set("github", {
        index: dummyIndex,
        name: "github",
        value: "github",
        display: { icon: "uil:github" },
      });

      mapping.set("slack", {
        index: dummyIndex,
        name: "slack",
        value: "slack",
        display: { icon: "uil:slack" },
      });

      mapping.set("gitlab", {
        index: dummyIndex,
        name: "gitlab",
        value: "gitlab",
        display: { icon: "ph:gitlab-logo-fill" },
      });

      const channels = newLabels.channels;
      const objects = newLabels.objects;

      const combined = [...newLabels.labels, ...newLabels.queries].sort(
        (a, b) => (b.calls || 0) - (a.calls || 0)
      );

      for (let i = 0; i < combined.length; ++i) {
        const item = combined[i];

        if (item.query) {
          const q = item as ApiQuery;
          const name = q.name;
          if (!mapping.has(name)) {
            mapping.set(name, {
              name: name,
              value: name,
              display: name,
              expression: q.query,
              index: i,
            });
          }
        } else {
          const l = item as ApiLabel;
          const name = l.name;
          let displayName = name;
          if (channels && channels[name]) {
            const channel = channels[name] as SlackChannel;
            displayName = `#${channel.name_normalized}`;
          } else if (objects && objects[name]) {
            const o = objects[name] as ApiObject;
            displayName = o.full_name;
          }

          const label: Label = mapping.get(name) || {
            name: name,
            display: displayName,
            value: name,
            index: i,
          };
          label.index = i;
          mapping.set(name, label);
        }
      }
      setActiveLabels((prev: any) => {
        for (let key of Array.from(mapping.keys())) {
          if (!prev || !prev[key]) {
            let obj: any = {};

            for (let [k, v] of Array.from(mapping.entries())) {
              if (v.index !== dummyIndex) {
                obj[k] = v;
              }
            }

            return obj;
          }
        }
        return prev;
      });
    } catch (err) {
      log.debug(err);
    } finally {
    }
  };

  useEffect(() => {
    const proj = project;
    if (proj) {
      getData(proj);
    }
  }, [reloadData, project]);
}

export const normalizeCompositeLabel = (label: string): string => {
  if (!label) {
    return label;
  }

  let slash = true;
  let res = "";
  for (let ch of label) {
    if (slash && ch !== "/") {
      slash = false;
      res += ch;
    } else if (!slash && ch === "/") {
      slash = true;
      res += ch;
    } else if (!slash) {
      res += ch;
    }
  }
  if (res && res[res.length - 1] === "/") {
    res = res.slice(0, res.length - 1);
  }

  return res;
};

const normalizeExpression = (expr: string): string => {
  return (
    " " +
    expr.replace(/\s+/g, " ").replace("(", " ( ").replace(")", " ) ") +
    " "
  );
};

export function StoryLabelFilterBox({ newLabel }: { newLabel: string }) {
  const [labels, setLabels] = useState<Label[]>();
  const project = useAtomValue(projectPickerState);
  const [negate, setNegate] = useAtom(negateLabelsState);
  const ref = useRef<{ prevClick?: number }>({});
  const activeLabels = useAtomValue(activeLabelsState);
  const [validExpression, setValidExpression] = useState<boolean>(true);
  const [expressionMembers, setExpressionMembers] = useState<Set<string>>();
  const [labelLookup, setLabelLookup] = useState<Set<string>>(new Set());

  const [globalFilters, setGlobalFilters] = useAtom(storyFilterBoxState);
  const [selected, setSelected] = useState<string[]>();

  const updateSelection = (selection: string[]) => {
    if (project) {
      const key = u.generateCacheKey("StoryLabelFilterBox", project);
      store(key, JSON.stringify(selection));
    }
    setSelected(selection);
  };

  useEffect(() => {
    if (project) {
      const key = u.generateCacheKey("StoryLabelFilterBox", project);
      let fromCache = JSON.parse(store.get(key, "[]"));
      if (!lodash.isArray(fromCache)) {
        fromCache = [];
      }
      setSelected((prev) => {
        if (!prev || !lodash.isEqual(fromCache, prev)) {
          return fromCache;
        }
        return prev;
      });
    }
  }, [project]);

  useEffect(() => {
    if (project && globalFilters && globalFilters[project]) {
      let uniq: Set<string> = new Set();
      for (let e of globalFilters[project]) {
        if (u.isLabelExpression(e)) {
          for (let op of riki.extract_expression_operands(e)) {
            uniq.add(op);
            if (op.includes("/")) {
              const norm = normalizeCompositeLabel(op);
              uniq.add(norm);
            }
          }
        }
      }

      if (uniq.size > 0) {
        setExpressionMembers(uniq);
        return;
      }
    }

    setExpressionMembers(undefined);
  }, [project, globalFilters]);

  useEffect(() => {
    if (project && selected && labels) {
      const substituted = selected.map((e: string) =>
        substituteExpression(e, labels)
      );

      setGlobalFilters((prev: any): any => {
        if (prev) {
          if (!lodash.isEqual(prev[project], substituted)) {
            let next: any = { ...prev };
            next[project] = substituted;
            return next;
          }
          return prev;
        } else {
          let next: any = {};
          next[project] = substituted;
          return next;
        }
      });
    }
  }, [project, selected, labels]);

  // labels created from ui
  useEffect(() => {
    setLabels((prev: Label[] | undefined): Label[] | undefined => {
      if (newLabel && newLabel.length) {
        let result = prev ? [...prev] : [];

        let names: string[] = [];
        if (prev) {
          names = prev.map((x: Label) => x.name);
        }

        for (let l of newLabel) {
          if (!names.includes(l)) {
            result.push({
              name: l,
              value: l,
              display: l,
              user_created: true,
              index: 9999,
            });
          }
        }
        return result;
      }
      return prev;
    });
  }, [newLabel, setLabels]);

  useEffect(() => {
    if (!activeLabels || !selected) {
      return;
    }

    let mapping = new Map<string, Label>();
    for (let [k, v] of Object.entries(activeLabels)) {
      mapping.set(k, v as Label);
    }

    let listing: Label[] = [];

    for (let v of Array.from(mapping.values())) {
      if (v.index >= 0) {
        listing.push(v);
      }
    }
    listing.sort((a, b) => a.index - b.index);

    // add any new labels that were created in the ui previously and are missing from backend
    if (labels) {
      for (let l of labels) {
        if (l.user_created && !mapping.has(l.name)) {
          listing.push(l);
          mapping.set(l.name, l);
        }
      }
    }
    setLabels((prev: Label[] | undefined): Label[] | undefined => {
      return lodash.isEqual(prev, listing) ? prev : listing;
    });

    let newSelected: string[] = [];
    let prevSelected = selected;
    for (let s of prevSelected) {
      if (u.isLabelExpression(s) || mapping.has(s)) {
        newSelected.push(s);
      }
    }
    if (!u.equalArrays(newSelected, prevSelected)) {
      updateSelection(newSelected);
    }

    let lookup: Set<string> = new Set();
    const addLookupLabel = (name: string) => {
      lookup.add(name);
      if (name.includes("/")) {
        const norm = normalizeCompositeLabel(name);
        lookup.add(norm);

        const parts = norm.split("/");
        for (let i = 1; i < parts.length; ++i) {
          const prefix = parts.slice(0, i).join("/");
          lookup.add(prefix);
        }
      }
    };

    for (let [k, v] of Array.from(mapping.entries())) {
      addLookupLabel(k);
      if (typeof v.display === "string") {
        addLookupLabel(v.display);
      }
    }
    setLabelLookup((prev) => (lodash.isEqual(lookup, prev) ? prev : lookup));
  }, [activeLabels, selected]);

  const replaceSelectedFilter = (
    selected: string[] | undefined,
    labels: string[]
  ) => {
    if (selected && u.equalArrays(selected, labels)) {
      updateSelection([]);
    } else {
      updateSelection(labels);
    }
  };

  const addLabelToSelection = (
    selected: string[] | undefined,
    labels: string[]
  ) => {
    if (selected) {
      const newFilters = labels
        .filter((e) => !selected.includes(e))
        .concat(selected.filter((e) => !labels.includes(e)));

      updateSelection(newFilters);
    } else {
      updateSelection(labels);
    }
  };

  const handleClick = (e: any, labelsClicked: string[]) => {
    if (labelsClicked.length === 0) {
      return;
    }

    // upate selected labels to be displayed on UI
    if (e.metaKey || e.shiftKey || e.ctrlKey) {
      addLabelToSelection(selected, labelsClicked);
    } else {
      replaceSelectedFilter(selected, labelsClicked);
    }

    // open in new tab when double clicked
    if (ref.current.prevClick) {
      const elapsed = e.timeStamp - ref.current.prevClick;
      if (e.detail > 1 && elapsed < 200 && project) {
        const url = u.getStoryUrlFromId(labelsClicked[0], project);
        window.open(url, "_blank", "noreferrer");
      }
    }
    ref.current.prevClick = e.timeStamp;
  };

  const handleClearSelection = () => {
    updateSelection([]);
  };

  const handleLabelSearch = (value: string, e: any) => {
    if (!value) {
      return;
    }

    const trimmedValue = value.trim();
    if (!u.isLabelExpression(trimmedValue)) {
      const norm = normalizeCompositeLabel(trimmedValue);

      const exactMatch = (e: Label) => {
        return (
          (typeof e.display === "string" &&
            (e.display === trimmedValue ||
              normalizeCompositeLabel(e.display) === norm)) ||
          (e.name &&
            (e.name === trimmedValue ||
              normalizeCompositeLabel(e.name) === norm))
        );
      };

      const compositeMatch = (e: Label) => {
        return (
          (typeof e.display === "string" &&
            normalizeCompositeLabel(e.display).startsWith(`${norm}/`)) ||
          (e.name && normalizeCompositeLabel(e.name).startsWith(`${norm}/`))
        );
      };

      const all: Label[] = labels
        ? labels.filter((e) => exactMatch(e) || compositeMatch(e))
        : [];
      const exact = labels
        ? labels.find(
            (e) => e.display === trimmedValue || e.value === trimmedValue
          )
        : undefined;
      const found = exact
        ? [exact].concat(all.filter((e) => e.name !== exact.name))
        : all;

      handleClick(
        e,
        found.map((e) => e.name)
      );
    } else {
      if (riki.validate_expression(trimmedValue)) {
        handleClick(e, [trimmedValue]);
      }
    }
    setValidExpression(true);
  };

  const handleSearchInput = (text: string) => {
    setValidExpression(isValidExpression(text, labelLookup));
  };

  const handleNegate = () => {
    u.writeLocalStorage("NegateLabels", negate ? "" : "true");
    setNegate((prev) => !prev);
  };

  const LABEL_SELECTED_COLOR = "green";
  const LABEL_NEGATIVE_COLOR = "tomato";
  const EXPR_SELECTED_COLOR = "#adc7b4";
  const EXPR_NEGATIVE_COLOR = "#c4afb3";

  const getBackgroundColor = (label: string): string => {
    if (selected && selected.includes(label)) {
      return negate ? LABEL_NEGATIVE_COLOR : LABEL_SELECTED_COLOR;
    } else if (expressionMembers) {
      const positive = EXPR_SELECTED_COLOR;
      const negative = EXPR_NEGATIVE_COLOR;
      if (expressionMembers.has(label)) {
        return negate ? negative : positive;
      } else if (label.includes("/")) {
        const parts = normalizeCompositeLabel(label).split("/");
        for (let i = 1; i < parts.length; ++i) {
          const prefix = parts.slice(0, i).join("/");
          if (expressionMembers.has(prefix)) {
            return negate ? negative : positive;
          }
        }
      }
    }

    return "#eeeeee";
  };

  const getColor = (label: string): string => {
    return selected && selected.includes(label) ? "white" : "black";
  };

  const getIconColor = (label: string): string => {
    if (selected && selected.includes(label)) {
      return negate ? LABEL_NEGATIVE_COLOR : LABEL_SELECTED_COLOR;
    } else if (expressionMembers && expressionMembers.has(label)) {
      return negate ? EXPR_NEGATIVE_COLOR : EXPR_SELECTED_COLOR;
    }

    return "grey";
  };

  const anyFiltersSelected = (): boolean => {
    return selected ? selected.length > 0 : false;
  };

  return (
    <Grid container spacing={0} sx={{ m: 0.5 }}>
      <Grid item>
        <ColoredSwitch
          colorChecked={red[600]}
          colorUnchecked={anyFiltersSelected() ? green[400] : undefined}
          onChange={handleNegate}
          checked={negate}
          inputProps={{ "aria-label": "Negate switch" }}
          size="small"
        />
      </Grid>
      <Grid item>
        <Tooltip
          title="Clear selection"
          placement="top-end"
          arrow
          enterDelay={500}
        >
          <span>
            <IconButton
              size="small"
              disabled={!anyFiltersSelected()}
              onClick={handleClearSelection}
              style={{ color: anyFiltersSelected() ? "lightpink" : undefined }}
            >
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {labels && (
        <Grid item>
          <AutocompleteButton
            variant="standard"
            options={Array.from(
              new Set(
                labels
                  ? labels.map((e: Label) =>
                      typeof e.display === "string" ? e.display : e.name
                    )
                  : []
              )
            )} // remove duplicates from there otherwise i'll get a key uniqueness error
            onSelected={handleLabelSearch}
            onTextInput={handleSearchInput}
            sx={validExpression ? undefined : { backgroundColor: "#fff0f0" }}
            placeholderText={undefined}
            onInput={undefined}
            disabled={undefined}
            renderOption={undefined}
          >
            <SearchIcon fontSize="inherit" />
          </AutocompleteButton>
        </Grid>
      )}
      {labels &&
        labels.map((item: Label) => (
          <Grid item key={item.name}>
            {typeof item.display === "string" && (
              <Chip
                size="small"
                style={{
                  background: getBackgroundColor(item.name),
                  color: getColor(item.name),
                  // maxWidth: 150
                }}
                label={item.display}
                onClick={(e) => {
                  handleLabelSearch(item.name, e);
                }}
              />
            )}
            {typeof item.display === "object" && (
              <IconButton
                style={{ color: getIconColor(item.name) }}
                size="small"
                onClick={(e) => {
                  handleClick(e, [item.name]);
                }}
              >
                <Icon icon={item.display.icon} />
              </IconButton>
            )}
          </Grid>
        ))}
    </Grid>
  );
}
