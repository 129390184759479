import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import { useRef, Fragment } from "react";

export function ImageBox({
  base64Image,
  fileObject,
  url,
  sx,
  filetype,
  refStory,
  onError,
  onLoad,
  onMouseMove,
  name,
  innerRef,
  readOnly,
}) {
  const ref = useRef();

  const getImage = () => {
    if (fileObject) {
      return fileObject;
    } else if (filetype && base64Image) {
      return `data:image/${filetype};base64,${base64Image}`;
    }
    return url;
  };

  const handleError = (e) => {
    if (onError) {
      onError(e);
    }
    if (onLoad) {
      onLoad();
    }
  };

  const handleLoad = (e) => {
    if (onLoad) {
      onLoad(e);
    }
  };

  if (readOnly) {
    return (
      <Box
        {...(refStory ? refStory : {})}
        ref={innerRef}
        component="img"
        sx={sx}
        src={getImage()}
        st_filename={(fileObject && fileObject.name) || name}
        onError={handleError}
        onLoad={handleLoad}
        onMouseMove={onMouseMove}
      />
    );
  } else {
    return (
      <Tooltip
        title="Double-click or hold Meta key to open in new tab"
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Box
          {...(refStory ? refStory : {})}
          ref={innerRef}
          component="img"
          sx={sx}
          src={getImage()}
          st_filename={(fileObject && fileObject.name) || name}
          onError={handleError}
          onLoad={handleLoad}
          onMouseMove={onMouseMove}
        />
      </Tooltip>
    );
  }
}
