import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import { Tooltip } from "@mui/material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useSetMessageProps } from "./hooks";

import { useState, useEffect, useRef } from "react";

import { useHttpPost } from "./hooks";

import languageEncoding from "detect-file-encoding-and-language";

import * as u from "./utility";
import { useNewTabText } from "./hooks";

export function codeStyle(fontSize) {
  return {
    ml: 0,
    mr: 0,
    mt: 0.1,
    mb: 0.1,
    pt: 0.1,
    pb: 0.1,
    pr: 1,
    pl: 1,
    background: "#505050",
    color: "#d5d5d5",
    fontSize: (11 * fontSize) / 13,
    borderRadius: "5px",
    position: "relative",
    display: "inline-block",
    width: "100%",
  };
}

export function TextPreview({
  data,
  file,
  item,
  onError,
  disableControls,
  fontSize,
}) {
  const [maxHeight, setMaxHeight] = useState(
    item ? u.getUiProp(item, "height", file.index, 300) : 300
  );
  const [anchor, setAnchor] = useState(null);
  const ref = useRef({ url: null });
  const [textData, setTextData, handleDoubleClick] = useNewTabText();
  const httpPost = useHttpPost();
  const [expand, setExpand] = useState(
    item ? u.getUiProp(item, "expand", file.index, 0) : 0
  );
  const setMessageProps = useSetMessageProps();

  const minHeight = 40;

  const decodeData = async () => {
    if (typeof data === "string") {
      setTextData(data);
      return;
    }

    // Uint8Array for files downloaded from S3
    // ArrayBuffer for files uploaded in browser -- FileUploadPreview
    // we need ArrayBuffer
    let buffer = ArrayBuffer.isView(data) ? data.buffer : data;
    let encoding = (await languageEncoding(new Blob([buffer]))).encoding;

    // the library we use will often detect GB18030 when feeding a binary file into it
    // this is some Chinese encoding, so i thing for now it's quite save to blacklist it
    if (encoding === "GB18030") {
      encoding = undefined;
    }

    if (buffer && encoding) {
      try {
        let decoder = new TextDecoder(encoding);
        const decoded = decoder.decode(buffer);
        setTextData(decoded);
      } catch (e) {
        if (onError) {
          onError();
        }
      }
    } else if (buffer && !encoding) {
      if (onError) {
        onError();
      }
    }
  };

  useEffect(() => {
    decodeData();
  }, []);

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    if (disableControls) {
      return;
    }

    setHover(true);
  };

  const handleMouseLeave = () => {
    if (disableControls) {
      return;
    }

    setHover(false);
    setAnchor(null);
  };

  const handleResize = (e) => {
    if (disableControls) {
      return;
    }

    e.preventDefault();
    let start = e.pageY;

    function onMouseMove(e) {
      setMaxHeight((prev) => {
        const newHeight = prev - start + e.pageY;
        if (newHeight > minHeight) {
          start = e.pageY;
          return newHeight;
        }
        return prev;
      });
    }
    function onMouseUp(e) {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
      setMaxHeight((height) => {
        setMessageProps(item, `st_ui/height/${file.index}`, height);
        return height;
      });
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);
  };

  const handleDownload = (e) => {
    var link = document.createElement("a");
    link.href = `data:{file.mimetype},` + encodeURIComponent(textData);
    link.download = file.name;
    link.click();
  };

  const handleExpand = () => {
    setMessageProps(item, `st_ui/expand/${file.index}`, expand ? 0 : 1);
    setExpand((prev) => !prev);
  };

  return (
    <Box
      component="span"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ width: "100%" }}
    >
      <Box
        sx={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        {hover && (
          <>
            <Box sx={{ position: "absolute", right: 1, top: 6, zIndex: 5 }}>
              <Stack direction="row" gap={0}>
                <Tooltip title={expand ? "Collapse" : "Expand"}>
                  <IconButton size="small" onClick={handleExpand}>
                    {expand ? (
                      <ZoomInMapIcon sx={{ color: "white", fontSize: 12 }} />
                    ) : (
                      <ZoomOutMapIcon sx={{ color: "white", fontSize: 12 }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Download ${file.name}`}>
                  <IconButton size="small" onClick={handleDownload}>
                    <DownloadIcon sx={{ color: "white", fontSize: 12 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      navigator.clipboard.writeText(textData);
                      setAnchor(e.currentTarget);
                      setTimeout(() => {
                        setAnchor(null);
                      }, 1000);
                    }}
                  >
                    <ContentCopyIcon
                      size="small"
                      sx={{ color: "white", fontSize: 12 }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Popper
                open={anchor != null}
                anchorEl={anchor}
                placement="right-start"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography fontSize={10} sx={{ p: 1 }}>
                        copied!
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>

            {!expand && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 2,
                  zIndex: 10,
                  cursor: "ns-resize",
                  height: 14,
                  width: "100%",
                  backgroundColor: "black",
                  opacity: "30%",
                }}
                onMouseDown={handleResize}
              ></Box>
            )}
          </>
        )}

        <Box
          sx={{
            ...codeStyle(fontSize),
            maxHeight: expand ? undefined : maxHeight,
            minHeight: minHeight,
            overflow: "auto",
          }}
          onClick={handleDoubleClick}
        >
          <Stack direction={"row"}>
            <pre
              style={{
                width: "100%",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {textData}
            </pre>
            <Box sx={{ width: "50px" }}></Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
