import CircleIcon from "@mui/icons-material/Circle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import ErrorIcon from "@mui/icons-material/Error";
import Popper from "@mui/material/Popper";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import { useAtomValue } from "jotai";

import * as emojiname from "emoji-name-map";
import { toBase64, toBytes } from "fast-base64";
import PouchDB from "pouchdb-browser";

import { useState, useEffect, Children, cloneElement } from "react";
import { useDownloadFile, useHttpPost, useSilentDownload } from "./hooks";

import { projectPickerState, imageToggleAtom } from "./JotaiAtoms";

import { FileUploadPreview, isTextFileType } from "./FileUploadPreview";
import { Pdf } from "./Pdf";
import { TextPreview, codeStyle } from "./TextPreview";
import { Image, imageTypeWhitelist } from "./Image";

import * as u from "./utility";

import { DEFAULT_FONT_SIZE, MAX_FILE_SIZE_PREVIEW } from "./constants";
import { PopupButton, PasswordInput } from "./Password";
import { decrypt } from "./crypto";
import { getRawMessageText } from "./storydat";
import { parseMarkdown, Markdown, FileAttachments } from "./Markdown";

function getMessageText(item) {
  const raw = getRawMessageText(item);
  if (raw.length > 0) {
    return raw;
  }

  return item.st_pp || "";
}

function isSupportedImage(fileData) {
  const filetypes = imageTypeWhitelist;
  const url = fileData.url_private_download;
  const fileSizeOk = fileData.size < MAX_FILE_SIZE_PREVIEW;
  let isImage = filetypes.has(fileData.filetype) && fileSizeOk;

  if (
    !fileData.filetype &&
    fileSizeOk &&
    filetypes.has(u.getExt({ url: url }))
  ) {
    isImage = true;
  }

  if (fileData.filetype === "binary" && fileSizeOk) {
    const ext = u.getExt({ url: url });
    if (ext === "avif") {
      isImage = true;
    }
  }

  return isImage;
}

function getSlackFilesToDownload(files, onlyImages) {
  if (files && files.length > 0) {
    let res = [];
    files.forEach((f, idx) => {
      let isImage = isSupportedImage(f);

      const url = f.url_private_download;
      if (onlyImages && isImage) {
        res.push({
          url: url,
          name: f.name,
          filetype: f.filetype,
          mimetype: f.mimetype,
          index: idx,
          size: f.size,
        });
      } else if (!onlyImages && !isImage) {
        res.push({
          url: url,
          name: f.name,
          filetype: f.filetype,
          mimetype: f.mimetype,
          index: idx,
          size: f.size,
        });
      }
    });
    return res;
  }
  return [];
}

function getFilesToDownload(item, onlyImages) {
  let files = [];
  if ("files" in item) {
    files = getSlackFilesToDownload(item.files, onlyImages);
  } else if ("st_files" in item) {
    // topstorie
    let conv = [];
    for (let f of item.st_files) {
      const type = u.getExt(f);
      conv.push({
        url_private_download: f.uri,
        size: f.size,
        filetype: type,
        mimetype: f.type,
        name: f.name,
      });
    }
    files = getSlackFilesToDownload(conv, onlyImages);
  }

  if (
    "old_dummy_message" in item &&
    "files" in item.old_dummy_message &&
    files.length === item.old_dummy_message.files.length
  ) {
    files.forEach((f) => {
      f.uploaded_file = item.old_dummy_message.files[f.index];
    });
  }

  return files;
}

function getAttachmentText(item) {
  if ("attachments" in item && item.attachments.length) {
    if ("fallback" in item.attachments[0]) {
      return item.attachments[0].fallback;
    }
    if ("text" in item.attachments[0]) {
      return item.attachments[0].text;
    }
  }
  return null;
}

function Reaction({ item, fontSize }) {
  let icons = { red_circle: CircleIcon, white_check_mark: CheckBoxIcon };
  let knownIcon = item.reaction in icons;
  const ReactionComponent = knownIcon ? icons[item.reaction] : AddReactionIcon;
  return (
    <Box>
      {knownIcon && <ReactionComponent />}
      {!knownIcon && (
        <Typography sx={{ fontSize: fontSize }}>
          <AddReactionIcon /> reaction: {item.reaction}
        </Typography>
      )}
    </Box>
  );
}

function MessageText({ item, storyKey, fontSize, overflow, sx }) {
  let text = getMessageText(item).trim();

  if (storyKey) {
    text = text
      .replaceAll(`STORY-${storyKey}`, "")
      .replaceAll(`[${storyKey}]`, "")
      .trim();
  }

  const parsed = parseMarkdown(text, item);

  // do not allow scroll if embedded images are present (github images for example)
  let boxs = overflow ? { overflow: "auto", maxHeight: 400 } : {};
  for (let m of parsed) {
    if (m.t == "img") {
      boxs = {};
      break;
    }
  }

  return (
    <Box sx={boxs}>
      <Markdown
        parsed={parsed}
        item={item}
        fontSize={fontSize}
        start={0}
        sx={sx}
      />
      {parsed.length > 0 && <br />}
    </Box>
  );
}

function PictureAttachments({ images, item, onLoad, fontSize, readOnly }) {
  const toggle = useAtomValue(imageToggleAtom);
  const [waiting, setWaiting] = useState(images.length);

  useEffect(() => {
    if (images.length > 0 && onLoad && waiting == 0) {
      onLoad();
    }
  }, [waiting]);

  if (!toggle) {
    return <CropOriginalIcon color="disabled" />;
  }

  if (images.length === 1) {
    return (
      <Image
        image={images[0]}
        item={item}
        index={0}
        onLoad={onLoad}
        fontSize={fontSize}
        readOnly={readOnly}
      />
    );
  }

  const handleLoad = () => {
    if (onLoad) {
      setWaiting((prev) => {
        return prev > 0 ? prev - 1 : 0;
      });
    }
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyItems={"flex-start"}
      alignItems={"flex-start"}
      spacing={0.1}
    >
      {images &&
        images.map((image, i) => (
          <Grid item key={image.index}>
            <Image
              image={image}
              sx={{ mr: 0.5 }}
              item={item}
              index={image.index}
              onLoad={handleLoad}
              fontSize={fontSize}
              readOnly={readOnly}
            />
          </Grid>
        ))}
    </Grid>
  );
}

function PdfAttachments({ files, item, fontSize }) {
  return (
    <Stack gap={1}>
      {files &&
        files.map((file, i) => (
          <Stack key={i} direction="row">
            <EmbeddedFile
              file={file}
              filetypes={new Set(["pdf"])}
              item={item}
              encoding="base64"
            >
              <Pdf fontSize={fontSize} />
            </EmbeddedFile>
          </Stack>
        ))}
    </Stack>
  );
}

function TxtAttachments({ files, item, fontSize, readOnly }) {
  return (
    <Stack gap={1}>
      {files &&
        files.map((file, i) => (
          <Stack key={i} direction="row">
            <EmbeddedFile file={file} item={item}>
              <TextPreview fontSize={fontSize} disableControls={readOnly} />
            </EmbeddedFile>
          </Stack>
        ))}
    </Stack>
  );
}

function EncryptedMessage({ message, fontSize, item, storyKey, overflow }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [decrypted, setDecrypted] = useState(null);
  const [error, setError] = useState(false);
  const [decryptInProgress, setDecryptInProgress] = useState(false);

  const handleRequestPassword = (e) => {
    setAnchorEl((prev) => (prev ? null : e.currentTarget));
  };

  const handlePasswordCancel = (e) => {
    setAnchorEl(null);
  };

  const handlePassword = (pass) => {
    const decryptMessage = async () => {
      try {
        const decrypted = await decrypt(pass, message);
        setDecrypted(decrypted);
        setAnchorEl(null);
      } catch (e) {
        setError(true);
      }
      setDecryptInProgress(false);
    };

    setDecryptInProgress(true);
    setTimeout(() => {
      decryptMessage();
    }, 300);
  };

  return (
    <>
      {decrypted && (
        <MessageText
          item={{ ...item, text: decrypted }}
          storyKey={storyKey}
          overflow={overflow}
          fontSize={fontSize}
        />
      )}

      {!decrypted && (
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            ...codeStyle(fontSize),
            position: undefined,
            display: undefined,
            width: "fit-content",
            background: "#7b7b9e",
            pl: 3,
            pt: 1,
            pb: 1,
          }}
          alignItems={"center"}
        >
          <Box>
            <Typography fontSize={fontSize}>
              CONTAINS CONFIDENTIAL MESSAGE
            </Typography>
          </Box>
          <PopupButton
            id={anchorEl ? "encrypted-message-popper" : undefined}
            onClick={handleRequestPassword}
          >
            Reveal
          </PopupButton>
          <Popper
            id={anchorEl ? "encrypted-message-popper" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
          >
            <Box
              six={{
                position: "relative",
                display: "inline-block",
              }}
            >
              {decryptInProgress && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "61%",
                    top: "68%",
                    zIndex: 5,
                  }}
                >
                  <CircularProgress size={35} thickness={10} />
                </Box>
              )}

              <PasswordInput
                confirm={false}
                onPassword={handlePassword}
                onCancel={handlePasswordCancel}
                label="Enter password to reveal message"
                error={error}
              />
            </Box>
          </Popper>
        </Stack>
      )}
    </>
  );
}

function ActualPreview({
  item,
  storyKey,
  fontSize,
  onLoad,
  overflow,
  readOnly,
  sx,
}) {
  const images = getFilesToDownload(item, true);
  const reaction = item.type === "reaction_added";
  const text = !reaction && getMessageText(item);
  const attachmentText = getAttachmentText(item);

  let pdfs = [];
  let txt = [];
  let otherFiles = [];
  for (let f of getFilesToDownload(item, false)) {
    if (f.size < MAX_FILE_SIZE_PREVIEW) {
      if (
        (f.mimetype && f.mimetype === "application/pdf") ||
        f.filetype === "pdf"
      ) {
        pdfs.push(f);
      } else if (isTextFileType({ type: f.mimetype, name: f.name })) {
        txt.push(f);
      } else {
        otherFiles.push(f);
      }
    } else {
      otherFiles.push(f);
    }
  }

  useEffect(() => {
    if (onLoad && images.length == 0) {
      onLoad();
    }
  }, [onLoad]);

  return (
    <>
      {reaction && (
        <Reaction item={item} fontSize={fontSize || DEFAULT_FONT_SIZE} />
      )}
      {text && (
        <MessageText
          item={item}
          storyKey={storyKey}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
          overflow={overflow}
          sx={sx}
        />
      )}

      {images.length > 0 && (
        <PictureAttachments
          images={images}
          item={item}
          onLoad={onLoad}
          fontSize={fontSize}
          readOnly={readOnly}
        />
      )}
      {pdfs.length > 0 && (
        <PdfAttachments
          files={pdfs}
          item={item}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
        />
      )}
      {txt.length > 0 && (
        <TxtAttachments
          files={txt}
          item={item}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
          readOnly={readOnly}
        />
      )}
      {otherFiles.length > 0 && <FileAttachments files={otherFiles} />}
      {attachmentText && (
        <Typography sx={{ fontSize: fontSize || DEFAULT_FONT_SIZE }}>
          {attachmentText}
        </Typography>
      )}
      {item.st_enc && (
        <EncryptedMessage
          message={item.st_enc}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
        />
      )}
    </>
  );
}

function DummyPreview({ item, storyKey, fontSize }) {
  const text = getMessageText(item);

  const isFileUpload = () => {
    return "type" in item && item.type === "topstorie_file_upload";
  };

  return (
    <>
      {text && (
        <>
          {item.error && (
            <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
              <ErrorIcon size="large" sx={{ color: "red", mr: 1 }} />
              <Box>
                <MessageText
                  item={item}
                  storyKey={storyKey}
                  fontSize={fontSize || DEFAULT_FONT_SIZE}
                />
              </Box>
            </Stack>
          )}
          {!item.error && (
            <Stack direction={"column"} sx={{ mb: 1 }}>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  color="secondary"
                  sx={{
                    backgroundColor: "lightgrey",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "silver",
                    },
                  }}
                />
              </Box>
              <Box>
                <MessageText
                  item={item}
                  storyKey={storyKey}
                  fontSize={fontSize || DEFAULT_FONT_SIZE}
                />
              </Box>
            </Stack>
          )}
        </>
      )}

      {isFileUpload() && item.files.length > 0 && (
        <FileUploadPreview item={item} files={item.files} />
      )}
    </>
  );
}

export function MessagePreview({
  item,
  storyKey,
  fontSize,
  overflow,
  readOnly,
  sx,
}) {
  const [loading, setLoading] = useState(
    !!(item.dummy || item.old_dummy_message)
  );

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {(item.dummy || item.old_dummy_message) && (
        <Box sx={{ lineHeight: 1, ...(loading ? {} : { display: "none" }) }}>
          <DummyPreview
            item={item.old_dummy_message || item}
            storyKey={storyKey}
            fontSize={fontSize}
          />
        </Box>
      )}
      {!item.dummy && (
        <Box sx={{ lineHeight: 1, ...(loading ? { display: "none" } : {}) }}>
          <ActualPreview
            item={item}
            storyKey={storyKey}
            fontSize={fontSize}
            onLoad={item.old_dummy_message ? handleLoad : undefined}
            overflow={overflow}
            readOnly={readOnly}
            sx={sx}
          />
        </Box>
      )}
    </>
  );
}

function EmbeddedFile({ file, filetypes, item, children, encoding }) {
  const [data, setData] = useState(null);
  const [wrongTypeOrError, setWrongTypeOrError] = useState(false);
  const [ext, setExt] = useState(u.getExt(file));
  const httpPost = useHttpPost();
  const projectName = useAtomValue(projectPickerState);
  const downloadFileFromS3 = useDownloadFile();
  const downloadSilentlyForFutureUse = useSilentDownload();

  const downloadFile = async (url, ext) => {
    const downloadS3 = async (url) => {
      let key = "";
      const hash = await u.sha1(url);

      if (u.getExtFromUrl(url)) {
        // ext is always lower case and in cache we nee the ext from the url verbatum
        key = `${projectName}/${hash}.${u.getExtFromUrl(url)}`;

        if (filetypes && !filetypes.has(ext)) {
          setWrongTypeOrError(true);
          return null;
        }
      } else {
        const metaKey = `${projectName}/${hash}.meta`;
        const bytes = await downloadFileFromS3(metaKey);
        const meta = JSON.parse(bytes.toString());

        let ext = undefined;
        if (meta.type.startsWith("image/")) {
          ext = meta.type.split("/").slice(-1)[0];
        } else if (meta.type.startsWith("text/")) {
          ext = "txt";
        }

        if (
          !filetypes ||
          filetypes.has(meta.type) ||
          (ext && filetypes.has(ext))
        ) {
          setExt(ext);
          key = `${projectName}/${hash}`;
        } else {
          setWrongTypeOrError(true);
          key = null;
        }
      }

      if (key) {
        const body = await downloadFileFromS3(key);
        if (encoding === "base64") {
          return await toBase64(body);
        } else {
          return body;
        }
      }

      return null;
    };

    let failed = false;
    while (true) {
      try {
        const body = await downloadS3(url);
        setData(body);

        const base64String =
          encoding === "base64" ? body : await toBase64(body);
        const db = new PouchDB(projectName);
        let doc = { _id: url, _attachments: { file: { data: base64String } } };
        if (file.mimetype) {
          doc._attachments.file.content_type = file.mimetype;
        }
        db.get(doc._id)
          .then((prev) => db.put({ _rev: prev._rev, ...doc }).catch((e) => {}))
          .catch((err) => db.put(doc).catch((e) => {}));

        return;
      } catch (err) {
        if (failed) {
          break;
        }
        failed = true;
      }

      try {
        const path = "slackfile?url=" + encodeURIComponent(url);
        await httpPost(path, null);
      } catch (err) {
        setWrongTypeOrError(true);
        break;
      }
    }
  };

  const readFileLocalOrRemote = async () => {
    let ok = false;
    if (file.uploaded_file) {
      const readFile = async () => {
        try {
          const buffer = await file.uploaded_file.arrayBuffer();
          let next = new Uint8Array(buffer);
          if (encoding === "base64") {
            next = await toBase64(next);
          }

          setData((prev) => {
            return prev ? prev : next;
          });
          return true;
        } catch (e) {}

        return false;
      };
      ok = await readFile();
      if (ok && file.url) {
        setTimeout(() => {
          downloadSilentlyForFutureUse(file.url, u.getExt(file));
        }, 2000);
      }
    }

    if (!ok && file.url) {
      const db = new PouchDB(projectName);
      db.get(file.url, { attachments: true })
        .then((doc) => {
          if (encoding === "base64") {
            setData((prev) => prev || doc._attachments.file.data);
          } else {
            toBytes(doc._attachments.file.data).then((b64) => {
              setData((prev) => prev || b64);
            });
          }
        })
        .catch((err) => {
          const ext = u.getExt(file);
          downloadFile(file.url, ext);
        });
    }
  };

  useEffect(() => {
    if (!data) {
      readFileLocalOrRemote();
    }
  }, [file, data]);

  const handleError = (e) => {
    setWrongTypeOrError(true);
  };

  const renderChildren = () => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        data: data,
        file: file,
        item: item,
        onError: handleError,
      });
    });
  };

  return (
    <>
      {!wrongTypeOrError && ext && data && <>{renderChildren()}</>}
      {(wrongTypeOrError || !ext || !data) && (
        <FileAttachments files={[file]} />
      )}
    </>
  );
}

export default MessagePreview;
